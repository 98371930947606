import { VMain } from 'vuetify/lib/components/VMain';
import { VResponsive } from 'vuetify/lib/components/VResponsive';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VMain,
    { attrs: { id: "pages" } },
    [
      _c(
        VResponsive,
        { attrs: { "min-height": "100vh" } },
        [_c("router-view")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }