import { VApp } from 'vuetify/lib/components/VApp';
import { VContent } from 'vuetify/lib/components/VContent';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(VApp, [_c(VContent, [_c("router-view")], 1)], 1)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }